import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/Animation/FadeInUp'
import React, { memo } from 'react'

export interface Props {
  description?: string
  title?: string
}

export const Item = memo(function Item({ description, title }: Props) {
  if (!description) {
    return null
  }

  return (
    <Container>
      {title ? (
        <FadeInUp>
          <Title>{title}</Title>
        </FadeInUp>
      ) : null}

      <FadeInUp>
        <Description dangerouslySetInnerHTML={{ __html: description }} />
      </FadeInUp>
    </Container>
  )
})

const Container = styled.div`
  width: 33.333%;
  background: ${({ theme }) => theme.colors.variants.neutralDark2};
  color: ${({ theme }) => theme.colors.variants.neutralLight5};
  padding: 3.75rem;
  &:nth-of-type(even) {
    background: ${({ theme }) => theme.colors.variants.primaryDark};
  }

  @media (max-width: 1199px) {
    width: 100%;
    padding: 2.5rem 1.875rem;
  }
`

const Title = styled.h2`
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.5rem;
  line-height: 1.5rem;
  margin-bottom: 1.875rem;

  @media (max-width: 1199px) {
    margin-bottom: 1.25rem;
  }
`

const Description = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1rem;
  line-height: 1.6875rem;

  p {
    margin-block-end: 1em;
  }
`
