import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/Animation/FadeInUp'
import { Info as Icon } from 'app/components/Icons'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'

import { Item } from './Item'

export interface Props {
  list: string[]
  title?: string
}

export const Info = memo(function Info({ list, title }: Props) {
  if (!list) {
    return null
  }

  return (
    <Container row stretch tag="section" wrap>
      <LeftSide>
        {title ? (
          <FadeInUp>
            <Title>{title}</Title>
          </FadeInUp>
        ) : null}

        <FadeInUp>
          <Icon />
        </FadeInUp>
      </LeftSide>

      <RightSide row stretch wrap>
        {list.map((item: any, index) => (
          <Item key={index} {...item} />
        ))}
      </RightSide>
    </Container>
  )
})

const Container = styled(FlexBox)`
  min-height: 33.75rem;

  @media (max-width: 1199px) {
    min-height: 27.5rem;
  }
`

const LeftSide = styled.div`
  width: 25%;
  background: ${({ theme }) => theme.colors.variants.primaryLight};
  padding: 3.75rem;

  svg {
    width: auto;
    height: 3.75rem;
    fill: ${({ theme }) => theme.colors.variants.primaryDark};
    margin-top: 1.25rem;
  }

  @media (max-width: 1199px) {
    width: 100%;
    padding: 2.5rem 1.875rem;

    svg {
      display: none;
    }
  }
`

const Title = styled.h2`
  max-width: 8.75rem;
  color: ${({ theme }) => theme.colors.variants.primaryDark};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 3.125rem;
  line-height: 3.125rem;

  @media (max-width: 1199px) {
    max-width: none;
    font-size: 2.5rem;
    line-height: 2.5rem;
  }
`

const RightSide = styled(FlexBox)`
  width: 75%;

  @media (max-width: 1199px) {
    width: 100%;
  }
`
